<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient :client_id="client_id" @getClient="getClient"/><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="dark-text header-w-bg bottom-20">Adult 65D-30 Intensive Outpatient ASAM Level II.1</div>
                <h3>[1] Admission Criteria</h3>

                <p>Check all items in each dimension that apply to the client. Place a check in the appropriate box that indicates validation or lack of validation for placement into this level of care.</p>
                <p>Must meet Dimensions 1, 2, or 3 and one of Dimensions 4, 5, or 6.</p>

                <div class="avoid-break">
                    <h3>Dimension 1: Acute Intoxication and/or Withdrawal Potential</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_one"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. Client is free from intoxication or withdrawal symptoms/risks; or',
                            'b. The client’s intoxication or withdrawal symptoms/risks can be managed at this level of care.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 2: Biomedical Conditions and Complications</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_two"
                        label=""
                        :options="[
                            'None or not a distraction from treatment and manageable in ASAM Level II.1. The client’s biomedical conditions, if any, are stable or are being concurrently addressed and will not interfere with treatment at this level of care.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 3: Emotional, Behavioral or Cognitive Conditions and Complications:</h3>
                    <p>
                        Problems in Dimension 3 are not necessary for admission to a Level II.1 program. However, if any of
                        the Dimension 3 conditions are present, the client must be admitted to either a Dual Diagnosis
                        Capable or Dual Diagnosis Enhanced program, depending on the client’s level of functioning,
                        stability and degree of impairment in this dimension.
                    </p>
                </div>
                <div class="avoid-break">
                    <h3>Dual Diagnosed Capable Programs</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_three_a"
                        label="The client’s status in Dimension 3 is characterized by (a) or (b):"
                        :options="[
                            'a. The client engages in abuse of family members or significant others, and requires intensive outpatient treatment to reduce the risk of further deterioration; or',
                            'b. The client has a diagnosed emotional, behavioral or cognitive disorder that requires intensive outpatient monitoring to minimize distractions from their treatment or recovery.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dual Diagnosed Enhanced Programs</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_three_b"
                        label="The client’s status in Dimension 3 is characterized by (a) or (b) or (c):"
                        :options="[
                            'a. The client has a diagnosed emotional, behavioral or cognitive disorder that requires management because the client’s history suggests a high potential for distracting him or her from treatment; such a disorder requires stabilization concurrent with addiction treatment; or',
                            'b. The client is assessed as at mild risk of behaviors endangering self, others or property; or',
                            'c. The client is at significant risk of victimization by another. However, the risk is not severe enough to require 24-hour supervision.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 4: Readiness to Change</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_four"
                        label="Resistance high enough to require a structured program but not so high as to render outpatient treatment ineffective. The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. The client requires structured therapy and a programmatic milieu to promote treatment progress and recovery because of failure at different levels of care. Such interventions are not likely to succeed at ASAM Level I service; or',
                            'b. The client’s perspective inhibits his/her ability to make behavior changes without clinically-directed and repeated structured motivational interventions. Such interventions are not feasible or not likely to succeed at ASAM Level I service. The client’s resistance, however, is not so high to render the treatment ineffective.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dual Diagnosed Enhanced Programs</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_four_b"
                        label="The client status in Dimension 4 is characterized by (a) or (b) or (c):"
                        :options="[
                            'a. The client is reluctant to agree to treatment and is ambivalent about his or her commitment to change a co-occurring mental health problem; or',
                            'b. The client’s follow through in treatment is so poor or inconsistent that Level I services are not succeeding or are not feasible; or',
                            'c. The client is assessed as requiring intensive services to improve his or her awareness of the need to change. The client has such limited awareness of or commitment to change that he or she cannot maintain an adequate level of functioning with out Level II.1 services.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 5: Relapse/Continued Use Potential</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_five"
                        label=""
                        :options="[
                            'Despite active participation at a less intensive level of care, the client is experiencing intensification of addiction symptoms (cravings/drug seeking related behavior) and is deteriorating in his/her level of functioning despite revisions in the treatment plan.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dual Diagnosed Enhanced Programs</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_five_b"
                        label=""
                        :options="[
                            'The client’s status in Dimension 5 is characterized by psychiatric symptoms that pose a moderate risk of relapse to the alcohol, drug or psychiatric disorder. The client has impaired recognition or understanding of and difficulty in managing relapse issues and requires Level II.1 Dual Diagnosis Enhanced Program services to maintain and adequate level of functioning.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 6: Recovery Environment</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_six"
                        label="Environment not supportive, but with structure and support the client can cope. The situation is characterized by one of the following:"
                        :options="[
                            'a. Continued exposure to current job, school or living environment will make recovery unlikely, and the client has insufficient or severely limited resources or skills needed to maintain an adequate level of functioning without this level of service; or',
                            'b. The client lacks social contacts, or has inappropriate social contacts that jeopardize recovery, or has few friends or peers who do not use alcohol/drugs. The client also has insufficient or severely limited resources or skills to maintain an adequate level of functioning without this level of service'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dual Diagnosed Enhanced Programs</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_six_b"
                        label=""
                        :options="[
                            'A living, working, social and/or community environment that is not supportive of good mental functioning characterizes the client status in Dimension 6. The client has insufficient resources and skills to deal with this situation.'
                        ]"
                    />
                </div>
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="recommendations"
                    v-model="rec.recommendations"
                    label="Recommendations/Notes:"
                    :limit=4000
                    inputStyle="min-height:20px;"
                />

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />            
            </VIfWorkableModal>
        </div>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';

    export default {
        name: 'IntensiveOutpatientASAM',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
            };
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>
